import React, { useState } from 'react';
import './App.css';
import Hoer from './ColorChangeButton';

function App() {

  // color is empty because passed, setColor is functie om color aan te passen
  const [color, setColor] = useState('');
  // <Hoer color='red'/> => color is prop name en red value

  return (
    <div className={`react-root ${color}`}>
      <div className='centered'>
        <h1>Color Picker</h1>

        <Hoer color='red' setColor={setColor}/>
        <Hoer color='blue' setColor={setColor}/>
        <Hoer color='yellow' setColor={setColor}/>

      </div>
    </div>
  );
}

export default App;
